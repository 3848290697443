import { Button } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { createTheme, ThemeProvider } from "@mui/material";

function ThemedButtonIcon(props) {


  const theme = createTheme({
    components: {
      MuiButton: {
        // shape: {
        //   borderRadius: 0,
        // },
        styleOverrides: {
          root: {
            borderRadius: props.borderRadius,
            backgroundColor: props.backgroundColor,
            color: props.color,
            '&:hover': {
              backgroundColor: props.color,
              color: props.backgroundColor
            },
            fontSize: "0.8rem",
            textAlign: "left"
          }
        }
      }
    }
  });
  return (
    <div className="subMenuButtonDiv">
      <ThemeProvider theme={theme}>
        <Button sx={{
          backgroundColor: props.showIcon ? props.color : props.backgroundColor,
          color: props.showIcon ? props.backgroundColor : props.color,
          
        }}
          style={{ justifyContent: /*"flex-start" */"space-between"}} variant="contained" name={props.name} disableElevation={props.disableElevation} className="buttonStyle mb-2"
          endIcon={props.showIcon ? <ChevronRightIcon fontSize="large" /> : null}
          onClick={props.handlClick}
        > {props.buttonText}</Button>
      </ThemeProvider>
    </div>);

}

export default ThemedButtonIcon;

// color={props.showIcon? props.backgroundColor: props.color}

